/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    ban: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15 8a6.97 6.97 0 00-1.71-4.584l-9.874 9.875A7 7 0 0015 8M2.71 12.584l9.874-9.875a7 7 0 00-9.874 9.874zM16 8A8 8 0 110 8a8 8 0 0116 0"/>',
    },
});
